<template>
  <div
    class="overlay-wrapper position-fixed top-0
    page-center-wrapper full-width-wrapper
    d-flex flex-column align-items-center bg-primary"
  >
    <!-- close Overlay button -->
    <div
      class="position-absolute close-overlay-button-wrapper
      bg-white me-auto ms-auto mt-5"
    >
        <a
          class="w-100 h-100 cursor-pointer text-decoration-none"
          aria-label="Close"
          @click.prevent="closeOverlay()"
        >
          <div class="w-100 h-100 product-overlay
          d-flex justify-content-center align-items-center">
            <i class="material-icons md-24 md-dark">close</i>
          </div>
        </a>
    </div>
    <!-- close Overlay button -->
    <div class="wrapper overlay-video-wrapper container-fluid mt-nav text-center">
      <!-- exhibtion details -->
      <h1 class="h2 text-uppercase mt-3 mt-md-4 mt-lg-0">
        {{ screening[index].Title }}
      </h1>
      <iframe
        :src="'https://www.youtube.com/embed/'+ screening[index].VideoId + '?enablejsapi=1&html5=1&rel=0'"
        width="100%" height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<script>

export default {
  props: ['screening', 'index'],
  data() {
    return {
    };
  },
  methods: {
    closeOverlay() {
      this.$emit('close-overlay');
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('overflow-hidden');
    },
  },
  mounted() {
    console.clear();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('overflow-hidden');
  },
};
</script>
