<template>
<!-- eslint-disable max-len -->
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!videoLoad"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <TopNav></TopNav>
  <!-- Background Video -->
  <div
  v-for="(video, index) in globalScreening"
  :key="video.TrailerVideoId"
  class="vimeo-screening-wrapper page-center-wrapper position-relative d-flex justify-content-center">
    <iframe
      :src="'https://player.vimeo.com/video/' + video.TrailerVideoId + '?background=1&autoplay=1&loop=1&byline=0&title=0'"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      @load="onVideoLoad(index)"
    >
    </iframe>
    <div
      class="video-title position-absolute text-white
      page-center-wrapper d-flex align-items-center justify-content-center
    top-0 w-100">
      <!-- This part below is for Facebook streaming with overlay -->
      <!-- <div class="col-12 col-lg-6"> -->
        <a
          :href="video.FacebookLink"
          target="_blank"
          class="cursor-pointer bg-primary
          col-12 col-lg-6
          text-uppercase text-decoration-none mx-3 mx-lg-0 px-3 px-lg-5 py-3"
        >
          <div
            v-if="video.Current"
            class="h2 large w-100 text-center mb-0 mb-lg-3"
          >
            live at <br> {{ video.LiveTime }}
          </div>
          <div
            v-else
            class="h2 large w-100 text-center
              mb-0"
          >
            past screening - <br class="d-block d-lg-none"> {{ convertDate[index].date }}&nbsp;
            {{ convertDate[index].month }}&nbsp;
            {{ convertDate[index].year }}
          </div>
          <div class="h3 text-center mt-2 mt-lg-0">
            {{ video.Title }}
          </div>
          <div class="h3 mt-4 text-secondary">
            <div class="d-flex align-items-center justify-content-center body-regular text-uppercase">
              <span class="mb-0">watch here</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
            </div>
          </div>
        </a>
      <!-- </div> -->
      <!-- This part above is for Facebook streaming with overlay -->

      <!-- This part below is for YouTube streaming with overlay -->
      <!-- <a
        href=""
        class="cursor-pointer bg-primary
        text-uppercase text-decoration-none mx-3 mx-lg-0 px-3 px-lg-5 py-3"
        @click.prevent="openVideoOverlay(index)"
      >
        <div
          v-if="video.Current"
          class="h2 large w-100 text-center mb-0"
        >
          live now
        </div>
        <div
          v-else
          class="h2 large w-100 text-center mb-0"
        >
          past screening - <br class="d-block d-lg-none"> {{ convertDate[index].date }}&nbsp;
          {{ convertDate[index].month }}&nbsp;
          {{ convertDate[index].year }}
        </div>
        <div class="h3 text-center mt-2 mt-lg-0">
          {{ video.Title }}
        </div>
        <div class="h3 mt-4 text-secondary">
          <div class="d-flex align-items-center justify-content-center body-regular text-uppercase">
            <span class="mb-0">watch here</span>
            <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-4" />
          </div>
        </div>
      </a> -->
      <!-- This part above is for YouTube streaming with overlay -->
    </div>
  </div>
  <!-- Background Video -->

  <!-- Overlay Video -->
  <transition name="fade">
    <GlobalScreeningOverlay
      v-if="videoOverlay"
      :screening="globalScreening"
      :index="selectedId"
      @close-overlay="closeVideoOverlay"
    >
    </GlobalScreeningOverlay>
  </transition>
  <!-- Overlay Video -->

  <!-- Bottom bar -->
  <div
    class="
      global-screening-bottom-bar
      position-fixed bottom-0
      row g-0
      text-white
      w-100
      p-0
    "
  >
    <div class="col-lg-6 bg-primary p-3 d-flex align-items-center">
      <h2 class="text-uppercase mb-0" :class="displayForward ? 'typing-text' : ''">
        {{ displayString }}
      </h2>
    </div>
  </div>
  <!-- Bottom bar -->
</template>

<script>
import gsap from 'gsap';
import gql from 'graphql-tag';
import TopNav from '@/components/front/TopNav.vue';
import Loading from '@/components/front/LoadingGeneral.vue';
import GlobalScreeningOverlay from '@/components/front/GlobalScreeningOverlay.vue';

export default {
  data() {
    return {
      strings: ['global screening | live streaming'],
      videoLoad: false,
      videoLoadLog: null,
      counter: 0,
      iterationCounter: 0,
      displayCounter: 0,
      displayString: '',
      displayForward: true,
      timeout: 100,
      globalScreening: [],
      convertDate: [],
      selectedId: '',
      videoOverlay: false,
    };
  },
  components: {
    Loading,
    TopNav,
    GlobalScreeningOverlay,
  },
  watch: {
    globalScreening: {
      deep: true,
      handler() {
        this.formatDate();
      },
    },
  },
  apollo: {
    globalScreening: {
      query: gql`
        query GlobalScreening {
          globalScreenings {
            Title
            Date
            FacebookLink
            LiveTime
            TrailerVideoId
            VideoId
            Current
          }
        }
      `,
      update(data) {
        return data.globalScreenings;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    onVideoLoad(index) {
      this.videoLoadLog.add(index);
      if (this.videoLoadLog.size === this.globalScreening.length) {
        this.videoLoad = true;
      }
    },
    /**
     * Main methods
     */
    displayText() {
      const textLength = this.strings[this.counter].length;
      setTimeout(() => {
        this.displayString = this.strings[this.counter].substring(0, this.displayCounter);
        if (this.displayForward) {
          this.displayCounter += 1;
          if (this.displayCounter <= textLength) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = false;
            this.displayText();
          }
        } else {
          // REVERSE TYPING
          this.displayCounter -= 1;
          if (this.displayCounter >= 0) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = true;
            this.displayText();
          }
        }
      }, this.timeout);
    },
    formatDate() {
      for (let i = 0; i < this.globalScreening.length; i += 1) {
        const date = new Date(this.globalScreening[i].Date);
        const longMonth = date.toLocaleString('en-us', { month: 'long' });
        const longYear = date.getFullYear();
        const longDate = date.getDate();
        const dateObj = {
          date: longDate,
          month: longMonth,
          year: longYear,
        };
        this.convertDate.push(dateObj);
      }
    },
    openVideoOverlay(index) {
      this.videoOverlay = true;
      this.selectedId = index;
      console.log(this.selectedId);
    },
    closeVideoOverlay() {
      this.videoOverlay = false;
    },
  },
  mounted() {
    this.videoLoadLog = new Set();
    setTimeout(() => {
      this.displayText();
    }, 1000);
  },
};
</script>
