<template>
  <!-- top Navbar -->
  <div
    class="top-navbar position-absolute top-0 left-0 bg-primary text-white px-3 py-2 p-lg-3 w-100"
  >
    <div class="d-flex justify-content-between align-items-center">
      <router-link
        class="en h3 nav-logo text-uppercase text-white text-decoration-none mb-0"
        to="/"
      >
        CITYA
      </router-link>
      <div class="h5 en mb-0">
        {{ convertedHours[currentCityIndex] }}:{{ minutes }}
         {{ timezone[currentCityIndex].city}}
      </div>
      <a
        v-if="!showMenu"
        href=""
        class="text-decoration-none"
        @click.prevent="openNav"
      >
        <span
          class="material-icons-outlined text-white"
        >
          menu
        </span>
      </a>
      <a
        v-if="showMenu"
        href=""
        @click.prevent="closeNav"
      >
        <span
          class="material-icons-outlined text-white"
        >
          close
        </span>
      </a>
    </div>
  </div>
  <!-- top Navbar -->

  <!-- open Nav -->
  <div
    class="navbar-open position-absolute hide-top left-0 w-100 page-center-wrapper"
    :class="{'show': showMenu}"
  >
    <div
      class="position-absolute left-0 d-flex flex-column background
    w-100 page-center-wrapper"
      :class="{'nav-open-background': showMenu}"
    >
      <div
        class="bg-primary w-100 h-100"
      ></div>
      <vLottiePlayer
        name="navAnimation"
        @animControl="anim => (navAnimation = anim)"
        width="100%"
        height="auto"
        path="https://assets8.lottiefiles.com/packages/lf20_1wvftyph.json"
      />
    </div>
    <div
      class="position-relative w-100 page-center-wrapper
      d-flex align-items-lg-center hide-top mt-sm-nav"
      :class="{'show': showMenu}"
    >
      <!-- <transition name="fade" :duration="3000"> -->
      <div
      class="menu-wrapper d-flex flex-column flex-lg-row
      row g-0 w-100
      px-3 px-lg-0"
      :class="{'nav-open-link-wrapper': showMenu}"
      >
        <div class="menu-left d-flex flex-column
        offset-0 offset-lg-1 col-12 col-lg-5">
          <router-link
            class="h2 text-uppercase text-white text-decoration-none"
            to="/global-screening"
            @click="closeNav"
          >
            global screening
          </router-link>
          <router-link
            v-for="city in menuCity"
            :key="city.city"
            class="h2 text-uppercase text-white text-decoration-none mt-2 mt-lg-4"
            :to="city.link"
            @click="closeNav"
          >
            {{ city.city }}
          </router-link>
        </div>
        <div
          class="menu-right
          d-flex flex-column align-items-start align-items-lg-end justify-content-lg-end
        col-12 col-lg-5 mt-5 mt-lg-0">
          <router-link
            class="h3 text-uppercase text-white text-decoration-none mb-2 mb-lg-3"
            to="/about"
            @click="closeNav"
          >
            about
          </router-link>
          <router-link
            class="h3 text-uppercase text-white text-decoration-none mb-2 mb-lg-3"
            to="/contact"
            @click="closeNav"
          >
            contact
          </router-link>
          <router-link
            class="h3 text-uppercase text-white text-decoration-none mb-2 mb-lg-3"
            to="/acknowledgement"
            @click="closeNav"
          >
            acknowledgement
          </router-link>
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
  <!-- open Nav -->
</template>

<script>
import emitter from '@/components/tools/emitter';

export default {
  data() {
    return {
      hour: [],
      convertedHours: [],
      minutes: '',
      seconds: '',
      timezone: [
        {
          utc: 8,
          city: 'HK',
          country: 'Hong Kong',
        },
        {
          utc: 8,
          city: 'MO',
          country: 'Macau',
        },
        {
          utc: 8,
          city: 'BJ',
          country: 'China',
        },
        {
          utc: -7,
          city: 'SF',
          country: 'United States',
        },
        {
          utc: 3,
          city: 'TLL',
          country: 'Estonia',
        },
        {
          utc: 2,
          city: 'RM',
          country: 'Italy',
        },
        {
          utc: 0,
          city: 'CITYA',
          country: 'CITYA',
        },
      ],
      currentCityIndex: 0,
      navAnimation: null,
      showMenu: false,
      disableMenuButton: false,
      menuCity: [
        {
          city: 'hong kong',
          link: '/city/hong-kong',
        },
        {
          city: 'macao',
          link: '/city/macao',
        },
        {
          city: 'beijing',
          link: '/city/beijing',
        },
        {
          city: 'san francisco',
          link: '/city/san-francisco',
        },
        {
          city: 'tallinn',
          link: '/city/tallinn',
        },
        {
          city: 'rome',
          link: '/city/rome',
        },
      ],
    };
  },
  watch: {
    hour: {
      deep: true,
      handler() {
        // console.log('emit-hour', this.hour[this.currentCityIndex]);
        emitter.emit('emit-hour', this.hour[this.currentCityIndex]);
      },
    },
  },
  methods: {
    openNav() {
      this.showMenu = true;
      this.navOnStop();
      setTimeout(() => {
        this.navOnPlay();
        this.disableMenuButton = true;
      }, 250);
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('overflow-hidden');
    },
    closeNav() {
      if (!this.disableMenuButton) {
        this.showMenu = false;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
        this.navOnPlay();
        setTimeout(() => {
          this.navOnStop();
        }, 1000);
      }
    },
    navOnPlay() {
      this.navAnimation.play();
      setTimeout(() => {
        this.navOnPause();
      }, 950);
    },
    navOnPause() {
      this.navAnimation.pause();
      this.disableMenuButton = false;
    },
    navOnStop() {
      this.navAnimation.stop();
    },
    getCountry() {
      this.$http
        .get(`https://extreme-ip-lookup.com/json/?key=${process.env.VUE_APP_IP_API_KEY}`)
        .then((res) => {
          this.currentCountry = res.data.country;
          switch (res.data.country) {
            case 'Hong Kong':
              this.currentCityIndex = 0;
              break;
            case 'Macau':
              this.currentCityIndex = 1;
              break;
            case 'China':
              this.currentCityIndex = 2;
              break;
            case 'United States':
              this.currentCityIndex = 3;
              break;
            case 'Estonia':
              this.currentCityIndex = 4;
              break;
            case 'Italy':
              this.currentCityIndex = 5;
              break;
            default:
              this.currentCityIndex = 6;
              break;
          }
        })
        .catch((data, status) => {
          console.log(data, status);
        });
    },
    getTime() {
      setTimeout(() => {
        const today = new Date();
        const h = today.getUTCHours();
        this.minutes = today.getUTCMinutes();
        this.seconds = today.getUTCSeconds();
        this.convertHourByTimeZone(h);
        this.fixLayout();
        this.getTime();
      }, 500);
    },
    convertHourByTimeZone(h) {
      for (let i = 0; i < 7; i += 1) {
        let hour = h + this.timezone[i].utc;
        if (hour >= 24) {
          hour -= 24;
        } else if (hour < 0) {
          hour += 24;
        }
        this.hour[i] = hour;
        if (hour < 10) {
          hour = `0${hour}`;
        }
        this.convertedHours[i] = hour;
      }
    },
    fixLayout() {
      // add zero in front of numbers < 10
      if (this.minutes < 10) {
        this.minutes = `0${this.minutes}`;
      }
      if (this.seconds < 10) {
        this.seconds = `0${this.seconds}`;
      }
    },
  },
  mounted() {
    this.getCountry();
    this.getTime();
  },
};
</script>
